<template>
    <b-row>
        <b-col cols="12">
            <b-card>
                <b-tabs>
                    <b-tab active>
                        <validation-observer
                            ref="observer"
                            v-slot="{ handleSubmit }"
                        >
                            <b-form
                                class="p-0"
                                @submit.prevent="handleSubmit(guardar)"
                            >
                                <!-- nombre_rol -->
                                <b-row>
                                    <b-col cols="12">
                                        <validation-provider
                                            v-slot="validationContext"
                                            name="Nombre"
                                            rules="required|max:50"
                                        >
                                            <b-form-group
                                                label="Nombre"
                                                label-for="txtNombre"
                                            >
                                                <b-form-input
                                                    trim
                                                    v-model="datos.nombre_rol"
                                                    :state="
                                                        getValidationState(
                                                            validationContext
                                                        )
                                                    "
                                                />
                                                <b-form-invalid-feedback>
                                                    {{
                                                        validationContext
                                                            .errors[0]
                                                    }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                </b-row>

                                <!-- Botones -->
                                <div class="d-flex float-right mt-2">
                                    <b-button
                                        class="mr-2"
                                        variant="primary"
                                        type="submit"
                                    >
                                        Guardar
                                    </b-button>
                                    <b-button
                                        variant="secondary"
                                        type="button"
                                        @click="cancelar"
                                    >
                                        Cancelar
                                    </b-button>
                                </div>
                            </b-form>
                        </validation-observer>
                    </b-tab>
                </b-tabs>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    BRow,
    BCol,
    BFormCheckbox,
} from "bootstrap-vue";
import axios from "@axios";
import router from "@/router";
import store from "@/store";
import { ref, nextTick } from "vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email, numeric } from "@validations";
import vSelect from "vue-select";
import utilerias from "@/views/utilerias";

const { msgToast, msjConfirmarGuardar, msjConfirmarCancelarEditar } =
    utilerias();

export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BFormInvalidFeedback,
        BButton,
        BFormSelect,
        BRow,
        BCol,
        BFormCheckbox,
        vSelect,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            datos: {
                nombre_rol: "",
            },
        };
    },
    async mounted() {
        await this.cargaRoles();
    },
    methods: {
        async cargaRoles() {
            const r = await axios.get(
                "/api/dragon/cat-roles/" + router.currentRoute.params.id
            );
            this.datos.nombre_rol = r.data.data["nombre_rol"];
        },

        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        cancelar() {
            msjConfirmarCancelarEditar.fire({}).then((result) => {
                if (result.isConfirmed) {
                    this.$refs.observer.reset();
                    router.go(-1);
                }
            });
        },
        guardar() {
            msjConfirmarGuardar.fire({}).then((result) => {
                if (result.isConfirmed) {
                    const payload = {
                        nombre_rol: this.datos.nombre_rol,
                    };
                    axios
                        .put(
                            "/api/dragon/cat-roles/" +
                                router.currentRoute.params.id,
                            payload,
                            { withCredentials: true }
                        )
                        .then((response) => {
                            msgToast.fire({
                                icon: "success",
                                title: "Rol actualizado",
                            });
                            router.go(-1);
                        });
                }
            });
        },
    },
};
</script>

<style>
.content-header .breadcrumb {
    display: flex !important;
}
</style>
